// src/config.js

const config = {
  port: 4200,

  // Configuração do authSv
  authSv:
    window.location.hostname === 'playground.btks.games'
      ? 'https://sv-test.btks.games'
      : window.location.hostname === 'adm.btks.games'
      ? 'https://sv.btks.games'
      : window.location.hostname === 'localhost'
      ? 'http://localhost'
      : 'https://sv.btks.games', // Padrão

  // Configuração do serverSv
  serverSv:
    window.location.hostname === 'playground.btks.games'
      ? 'https://sender-test.btks.games'
      : window.location.hostname === 'adm.btks.games'
      ? 'https://sender.btks.games'
      : 'https://sender.btks.games', // Padrão

  domains: [
    'brabaum.network',
    'goysdegoy.network',
    'willzera.network',
    'dogblin.network',
    'metralhas.network',
    'willzera.network',
    'xinxa.network',
    'loira.games',
  ],
  enabled: true,
};

// Exportação para CommonJS
module.exports = config;

// Remova ou comente a linha abaixo
// export default config;
