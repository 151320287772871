import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import AuthService from '../../services/AuthService';
import useAuth from '../../hooks/useAuth';

export default function Login() {
  const { setToken, setUser } = useAuth();
  const history = useHistory(); // Hook para redirecionamento

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async ({ username, password }) => {
      setLoading(true);

      try {
        const response = await AuthService.login(username, password);
        const { token, user } = response.data;

        if (token && user) {
          setToken(token);
          setUser(user);
          history.push('/online'); // Redirecionar para a tela de logado
        }
      } catch (err) {
        alert(
          err?.response?.data?.message || 'Houve uma falha na requisição.'
        );
      } finally {
        setLoading(false);
      }
    },
    [setUser, setToken, history]
  );

  return (
    <Container component={Form} onSubmit={handleSubmit}>
      <Box mt={6}>
        <Grid container justifyContent="center">
          <Grid
            item
            container
            spacing={4}
            component={Paper}
            style={{
              minWidth: 320,
              maxWidth: 480,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                Login do Painel
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input name="username" label="Usuário" />
            </Grid>
            <Grid item xs={12}>
              <Input type="password" name="password" label="Senha" />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button type="submit" loading={loading}>
                  Entrar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
