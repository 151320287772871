import React, { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';

import Copy from '../../components/Copy';
import Input from '../../components/Form/Input';
import SendMail from '../../components/SendMail';
import Table from '../../components/Table';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';

const Icons = require('@material-ui/icons');

export default function ({ setTitle, setHeaderRight }) {
  useEffect(() => setTitle('Send Mails'), [setTitle]);
  useEffect(() => {
    setHeaderRight(
      <FormControlLabel
        control={
          <>
            <Button
              onClick={() => {
                API.get(`/settings/blocked`).then(({ data }) => setBlockedEmailHashes((data.emailhashes || []).join(', ')));
              }}
            >
              <Icons.Email />
              &nbsp;&nbsp; Blocked MailHashes
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              onClick={() => {
                if (window.confirm(`Are yo sure to reset the send count of mailer?`)) {
                  API.post(`/mailer/reset-result-report`, {});
                }
              }}
            >
              <Icons.Restore />
              &nbsp;&nbsp; Reset sent count
            </Button>
          </>
        }
      />
    );
    return () => setHeaderRight(null);
  }, [setHeaderRight]);

  const { user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [upsertTask, setUpsertTask] = useState(null);
  const [senderStatus, setSenderStatus] = useState({});
  const [poolStatus, setPoolStatus] = useState({});
  const [senderToggleStatus, setSenderToggleStatus] = useState({});
  const [domains, setDomains] = useState([]);
  const [mailers, setMailers] = useState([]);
  // const [routers, setRouters] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSpeed, setAnchorSpeed] = useState(null);

  const [currentMailer, setCurrentMailer] = useState(null);
  const [currentMailGun, setCurrentMailGun] = useState(null);
  const [currentOutlook, setCurrentOutlook] = useState(null);
  const [currentSendInBlue, setCurrentSendInBlue] = useState(null);
  const [currentLocalWeb, setCurrentLocalWeb] = useState(null);
  const [currentSendGrid, setCurrentSendGrid] = useState(null);
  const [logMailer, setLogMailer] = useState(null);
  const [offset, setOffset] = useState(0);

  const [showTasks, setShowTasks] = useState(false);
  const [showDomain, setShowDomain] = useState(false);
  const [showLog, setShowLog] = useState(true);
  const [showMailers, setShowMailers] = useState(true);
  const [showGoodDomain, setShowGoodDomain] = useState(true);

  const [sendTestMailer, setSendTestMailer] = useState(false);
  const [filterBlocked, setFilterBlocked] = useState(true);
  const [filterType, setFilterType] = useState('');
  const [filterOriginalType, setFilterOriginalType] = useState('');
  const [filterSuccessLog, setFilterSuccessLog] = useState(true);

  const [mailerTypes, setMailerTypes] = useState([]);

  const [time, setTime] = useState(0);
  const [blockedEmailHashes, setBlockedEmailHashes] = useState(null);
  const refreshDomains = () => {
    API.get(`/public/mailer/domains`).then((res) => {
      let goodDomains = res.data.filter((domain) => domain.hetrix && !domain.hetrix?.blocked);
      let badDomains = res.data.filter((domain) => !domain.hetrix || domain.hetrix.blocked);
      if (user.username !== 'admin') {
        const offest = 0; //Math.floor((goodDomains.length - 5) * Math.random());
        goodDomains = goodDomains.slice(offest, offset + 5);
        badDomains = [];
      }
      setDomains([...badDomains, ...goodDomains]);
    });
    // API.get(`/dns/r53`).then((res) => setRouters(res.data || []));
  };
  const refresh = () => {
    console.log('Calling API to fetch tasks...');
    
    API.get('/task-send')
      .then((res) => {
        console.log('API response received:', res);
  
        if (res.data && res.data.success) {
          console.log('Response is successful:', res.data);
  
          const tasks = (res.data.tasks || []).map((task) => {
            console.log('Processing task:', task);
  
            let templates = [];
            let options = {};
  
            try {
              templates = JSON.parse(task.templates || '[]');
            } catch (e) {
              console.error('Error parsing templates:', e);
            }
  
            try {
              options = JSON.parse(task.options || '{}');
            } catch (e) {
              console.error('Error parsing options:', e);
            }
  
            const processedTask = {
              ...task,
              templates,
              options,
            };
  
            console.log('Processed task:', processedTask);
            return processedTask;
          });
  
          console.log('Final tasks list:', tasks);
          setTasks(tasks);
        } else {
          console.error('Failed to fetch tasks or success is false:', res.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
      });
  };
  
  
  
  useEffect(() => {
    API.get(`/template`).then((r) => setTemplates(r.data));
    refresh();
    refreshDomains();
    const timer = setInterval(() => setTime(new Date()), 8000);
    API.get(`/mailers?blocked=&type=none`).then((res) => {
      setMailerTypes(res.data.types || []);
    });
    return () => clearInterval(timer);
  }, [user]);
  useEffect(() => {
    API.get(`/settings/mailer-results`).then((res) => {
      setSenderStatus(res.data);
    });
    API.get(`/settings/mailer-paused`).then((res) => {
      setSenderToggleStatus(res.data || {});
    });
    API.get(`/settings/mailer-pool-status`).then((res) => {
      setPoolStatus(res.data);
    });
  }, [time]);

  useEffect(() => {
    if (filterType) {
      API.get(`/mailers?&blocked=${filterBlocked ? 'true' : ''}&type=${filterType}`).then((res) => {
        setMailers(res.data.list || []);
        setMailerTypes(['digitalocean-', 'linode-', 'G-Core-', ...(res.data.types || []).sort((a, b) => (a > b ? 1 : -1))]);
      });
    }
  }, [filterBlocked, filterType, time]);

  useEffect(() => {
    if (!filterType && mailerTypes.length) {
      setFilterType(mailerTypes[0]);
    }
  }, [filterType, mailerTypes]);

  const hotmailSuccess = senderStatus?.hotmail?.success || 0;
  const othermailSuccess = senderStatus?.othermail?.success || 0;

  const colorIP = (enabled, status, ip) => {
    const getStyle = () => {
      if (!enabled) {
        return { color: 'gray', tooltip: 'Mailer desativado' };
      }
  
      if (status.blocked) {
        if (status.blocked.type === 'permanent') {
          return { color: '#FF0000', tooltip: 'IP bloqueado permanentemente' }; // Vermelho para bloqueio permanente
        }
        if (status.blocked.type === 'temporary') {
          return { color: '#0000FF', tooltip: 'IP bloqueado temporariamente' }; // Azul para bloqueio temporário
        }
      }
  
      if (status.goodIPs && status.goodIPs.includes(ip)) {
        return { color: '#00FF00', tooltip: 'Bom IP' }; // Verde claro para IPs bons
      }
  
      // Caso padrão para IPs que não estão na lista de bons IPs e não estão bloqueados globalmente
      return { color: '#FFA500', tooltip: 'IP com status desconhecido' }; // Laranja para status desconhecido
    };
  
    const style = getStyle();
  
    return (
      <Tooltip title={style.tooltip}>
        <span style={{ color: style.color }}>{ip}</span>
      </Tooltip>
    );
  };
  
  return (
    <>
      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <Button size="small" variant="outlined" onClick={() => setUpsertTask({})}>
              <Icons.Add fontSize="small" />
            </Button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp; Tasks ({tasks?.length})</span>
          </span>
          {user.username === 'admin' && (
            <span style={{ marginTop: 8 }}>
              Hotmail:&nbsp;
              <span style={{ color: 'lightgreen' }}>{hotmailSuccess}</span>
              &nbsp;&nbsp;&nbsp; Othermail:&nbsp;
              <span style={{ color: '#f8f' }}>{othermailSuccess}</span>
            </span>
          )}
          <span>
            <Button onClick={() => setShowTasks((current) => !current)}>{!showTasks ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showTasks && (
          <Table
            size="small"
            // onRowClick={(e, row) => setCurrentTask(row)}
            data={tasks || []}
            columns={[
              {
                title: 'Name',
                attribute: 'name',
              },
              {
                title: 'Template',
                attribute: 'template',
                Cell: ({ _id, templates, mailGroup }) => (
                  <>
                    {templates.map((template, index) => {
                      const success = mailGroup
                        .filter((m) => (senderStatus[template._id] || {})[m.group])
                        .map((m) => senderStatus[template._id][m.group]?.success || 0)
                        .reduce((acc, a) => acc + a, 0);
                      const failed = mailGroup
                        .filter((m) => (senderStatus[template._id] || {})[m.group])
                        .map((m) => senderStatus[template._id][m.group]?.failed || 0)
                        .reduce((acc, a) => acc + a, 0);
                      return (
                        <div key={index} style={{ margin: 3, backgroundColor: '#1112' }}>
                          <div style={{ backgroundColor: '#1112', padding: 3 }}>{template.subject}</div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', padding: 3 }}>
                            <span style={{ color: '#ff88' }}>({template.hotmailerDomain})&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <span style={{ color: '#ffc' }}>{success}</span>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ),
              },
              {
                title: 'Type',
                Cell: ({ mailGroup, _id }) => (
                  <table cellPadding={5}>
                    <tr style={{ backgroundColor: '#1116' }}>
                      <th align="center">Group</th>
                      <th align="center">%</th>
                      <th align="center">Option</th>
                      <th>Success</th>
                      <th>Pool</th>
                    </tr>
                    {mailGroup.map((m, index) => {
                      const success = Object.values(senderStatus)
                        .map((v) => v[m.group]?.success || 0)
                        .reduce((acc, a) => acc + a, 0);
                      const failed = Object.values(senderStatus)
                        .map((v) => v[m.group]?.failed || 0)
                        .reduce((acc, a) => acc + a, 0);
                      const poolLength = (poolStatus||{})[`${_id}/${m.group}`] || 0;
                      return (
                        <tr key={index} style={{ whiteSpace: 'nowrap', backgroundColor: '#1112' }}>
                          <td align="center" style={{ color: '#ddd' }}>
                            {m.group}
                          </td>
                          <td style={{ color: '#ddd' }}>{m.percent}%</td>
                          <td>
                            <span style={{ color: m.multiple ? 'yellow' : 'gray', textDecoration: !m.multiple ? 'line-through' : 'inherit' }}>multiple</span>
                          </td>
                          <td align="right">
                            <span style={{ color: '#cfc' }}>{success}</span>
                          </td>
                          <td align="right">
                            <span style={{ color: poolLength > 8000 ? '#8f8' : poolLength > 4000 ? '#fc8' : '#f88' }}>{poolLength}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                ),
              },
              {
                title: 'SenderGroup Include',
                Cell: ({ senderGroup }) => <span style={{ color: '#8f88' }}>{(senderGroup || []).join(', ')}</span>,
              },
              {
                title: 'SenderGroup Exclude',
                Cell: ({ senderGroupExclude }) => <span style={{ color: '#f888' }}>{(senderGroupExclude || []).join(', ')}</span>,
              },
              {
                title: 'Options',
                Cell: ({ options }) => (
                  <>
                    <div style={{ color: options.resend ? 'lightgreen' : 'gray' }}>Resend</div>
                    <div style={{ color: options.random ? 'lightgreen' : 'gray' }}>Random</div>
                    <div style={{ color: options.autosend ? 'lightgreen' : 'gray' }}>Autosend</div>
                  </>
                ),
              },

              {
                title: 'Created',
                attribute: 'createdAt',
                Cell: ({ createdAt }) => new Date(createdAt).toLocaleString(),
              },
              {
                title: 'Action',
                Cell: (row) => (
                  <>
                    <ButtonGroup size="small">
                      <Button
                        title="Toggle"
                        onClick={() => {
                          API.put(`/task-send/${row._id}/${row.status === 'Running' ? 'Paused' : 'Running'}`).then(refresh);
                        }}
                        disabled={row.status === 'Done'}
                      >
                        {row.status === 'Running' ? (
                          <Icons.Pause fontSize="small" style={{ color: 'lightgreen' }} />
                        ) : row.status === 'Paused' ? (
                          <Icons.PlayArrow fontSize="small" style={{ color: '#F228' }} />
                        ) : (
                          <Icons.StopOutlined fontSize="small" style={{ color: 'gray' }} />
                        )}
                      </Button>
                      <Button
                        title="Update"
                        onClick={() => {
                          setUpsertTask(row);
                        }}
                      >
                        <Icons.Edit fontSize="small" />
                      </Button>
                      <Button
                        title="Remover"
                        onClick={() => {
                          if (window.confirm(`Are you sure to delete Task`)) API.delete(`/task-send/${row._id}`).then(() => refresh());
                        }}
                      >
                        <Icons.Delete fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  </>
                ),
              },
            ]}
          />
        )}
      </Box>

      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <span>
            {/* <Button
              size="small"
              variant="outlined"
              onClick={() => {
                const count = window.prompt(`Insert count to add domain`);
                if (+count) {
                  API.post(`/dns/${count}`).then((r) => refreshDomains());
                }
              }}
              disabled={domains.length >= MAX_DOMAINS_PER_ACCOUNT}
            >
              <Icons.Add fontSize="small" />
            </Button> */}
            &nbsp;&nbsp;&nbsp;&nbsp; Domains ({domains?.length}) &nbsp;&nbsp;&nbsp;&nbsp;
            <FormControlLabel
              control={
                <Switch
                  checked={showGoodDomain}
                  onChange={(e, value) => {
                    setShowGoodDomain(value);
                  }}
                />
              }
              label={(showGoodDomain ? `Good Domain ` : 'Bad Domain ') + `(${domains.filter((a) => !!a?.hetrix?.blocked !== showGoodDomain).length})`}
              disabled={user.username !== 'admin'}
            />
          </span>
          <span>
            <Button onClick={() => setShowDomain((current) => !current)}>{!showDomain ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showDomain && (
          <Table
            size="small"
            data={domains.filter((a) => !!a?.hetrix?.blocked !== showGoodDomain).map((a, index) => ({ ...a, index: index + 1 })) || []}
            columns={[
              {
                title: 'No',
                attribute: 'index',
              },
              {
                title: 'Domain',
                attribute: 'domain',
                Cell: ({ domain }) => <Copy value={domain}>{domain}</Copy>,
              },
              {
                title: 'Blocked',
                attribute: 'hetrix',
                Cell: ({ hetrix }) =>
                  hetrix ? (
                    <>
                      <span style={{ color: !hetrix.blocked ? 'lightgreen' : '#F888' }}>{!hetrix.blocked ? 'Good' : 'Bad'}</span> ({new Date(hetrix.checkedAt).toLocaleString()})
                    </>
                  ) : (
                    '-'
                  ),
              },
              {
                title: 'MX',
                Cell: ({ mxs }) => (
                  <>
                    {mxs
                      .filter((mx) => user.username === 'admin' || mailers?.map((m) => m.ip).includes(mx.ip))
                      .map((mx, index) => (
                        <span key={index} style={{ backgroundColor: '#FFF1', padding: 3, margin: 3, borderRadius: 3 }}>
                          {mx.ip} ({mx.ptr})
                        </span>
                      ))}
                  </>
                ),
              },
            ]}
            pagination={true}
          />
        )}
      </Box>

      <Box component={Paper} mb={2} padding={1}>
        <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {/* <Button size="small" variant="outlined" onClick={() => setCurrentMailer({})}>
            </Button> */}
            <Button aria-controls={!!anchorEl ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={!!anchorEl ? 'true' : undefined} onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Icons.Add fontSize="small" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              style={{ marginTop: 40, marginLeft: 30 }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setCurrentMailer({});
                }}
              >
                Postfix
              </MenuItem>
              {user.username === 'admin' && (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setCurrentOutlook({});
                    }}
                  >
                    Outlook
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setCurrentMailGun({});
                    }}
                  >
                    MailGun
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setCurrentSendInBlue({});
                    }}
                  >
                    SendInBlue
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setCurrentLocalWeb({});
                    }}
                  >
                    LocalWeb
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setCurrentSendGrid({});
                    }}
                  >
                    SendGrid
                  </MenuItem> */}
                </>
              )}
            </Menu>
            &nbsp;&nbsp;&nbsp;&nbsp; Mailers &nbsp;&nbsp;&nbsp;&nbsp;
            <FormControlLabel
              control={
                <Switch
                  checked={filterBlocked}
                  onChange={(e, value) => {
                    setFilterBlocked(value);
                  }}
                />
              }
              label={filterBlocked ? 'Good Mailers' : 'Blocked Mailers'}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <TextField
            // variant="outlined"
            select
            label="Type"
            value={filterType}
            onChange={(e, value) => {
              setMailers([]);
              setFilterType(e.target.value);
            }}
            style={{ width: 300, marginTop: -15 }}
          >
            {mailerTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <span>&nbsp;&nbsp;&nbsp;</span>

          <span></span>
          <span>
            {/* <Button
              onClick={() => {
                if (window.confirm(`Are you sure to update mailers in the current page?`)) {
                  mailers.forEach((mailer) => {
                    API.post(`/mailer/${mailer.ip}/install`);
                  });
                }
              }}
            >
              <Icons.CloudDownload fontSize="small" /> &nbsp;&nbsp;&nbsp;Reinstall All
            </Button> */}
            <Tooltip title={(senderToggleStatus || {})[filterType] ? 'Resume' : 'Pause'}>
              <Button
                onClick={() => {
                  if (window.confirm(`Are you sure to ${(senderToggleStatus || {})[filterType] ? 'Resume' : 'Pause'} ${filterType}?`)) {
                    API.post(`/settings/mailer-paused`, {
                      ...senderToggleStatus,
                      [filterType]: !(senderToggleStatus || {})[filterType],
                    });
                  }
                }}
                style={{ color: (senderToggleStatus || {})[filterType] ? '#f88c' : '#8f8c' }}
              >
                {(senderToggleStatus || {})[filterType] ? <Icons.PlayArrow fontSize="small" /> : <Icons.Pause fontSize="small" />}
                &nbsp;&nbsp;&nbsp;{(senderToggleStatus || {})[filterType] ? 'Resume' : 'Pause'}
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Change Type of All">
              <Button
                onClick={() => {
                  const type = window.prompt(`Insert new type`, filterType || 'type');
                  if (type) {
                    mailers.forEach((mailer) => {
                      API.put(`/mailer/${mailer.ip}`, { type });
                    });
                  }
                }}
                style={{ color: '#aff' }}
              >
                <Icons.Toys fontSize="small" /> &nbsp;&nbsp;&nbsp;Type
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Update Speed of All">
              <Button aria-controls={!!anchorSpeed ? 'speed-menu' : undefined} aria-haspopup="true" aria-expanded={!!anchorSpeed ? 'true' : undefined} onClick={(e) => setAnchorSpeed(e.currentTarget)}>
                <Icons.Speed fontSize="small" /> &nbsp;&nbsp;&nbsp;Speed
              </Button>
            </Tooltip>
            <Menu
              id="speed-menu"
              anchorEl={anchorSpeed}
              open={!!anchorSpeed}
              onClose={() => setAnchorSpeed(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              style={{ marginTop: 40, marginLeft: 30 }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorSpeed(null);
                  const speed = window.prompt(`Insert new speed of Hotmail`);
                  if (speed) {
                    mailers.forEach((mailer) => {
                      API.put(`/mailer/${mailer.ip}`, { hotmailSpeed: +speed || 0 });
                    });
                  }
                }}
              >
                Hotmail
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorSpeed(null);
                  const speed = window.prompt(`Insert new speed of Othermail`);
                  if (speed) {
                    mailers.forEach((mailer) => {
                      API.put(`/mailer/${mailer.ip}`, { othermailSpeed: +speed || 0 });
                    });
                  }
                }}
              >
                Othermail
              </MenuItem>
            </Menu>
            &nbsp;&nbsp;&nbsp;
            <Tooltip title="Delete All">
              <Button
                onClick={() => {
                  if (window.confirm(`Are you sure to delete all mailers of ${filterType}?`)) {
                    mailers.forEach((mailer) => {
                      API.delete(`/mailer/${mailer.ip}`);
                    });
                  }
                }}
                style={{ color: '#faa' }}
              >
                <Icons.Delete fontSize="small" />
                &nbsp;&nbsp;&nbsp;Remove
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={() => setShowMailers((current) => !current)}>{!showMailers ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </span>
        </div>
        {showMailers && (
          <>
            <div style={{ fontSize: 16, position: 'relative', top: 14, left: 30, height: 0 }}>
              Total: {mailers.length} mailers &nbsp;&nbsp;&nbsp;<span style={{ color: 'lightgreen' }}>{mailers.reduce((acc, a) => acc + a?.status?.successSent || 0, 0)}</span>&nbsp;/&nbsp;
              {mailers.reduce((acc, a) => acc + a?.status?.sent || 0, 0)}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <TextField
                // variant="outlined"
                select
                label="Original Type"
                value={filterOriginalType}
                onChange={(e, value) => {
                  setFilterOriginalType(e.target.value);
                }}
                style={{ width: 150, marginTop: -20, zIndex: 1 }}
                disabled={mailers[0]?.type !== 'Pending'}
              >
                <MenuItem key={-1} value="">
                  All
                </MenuItem>
                {mailers
                  .filter((m) => m.type === 'Pending')
                  .map((m) => m.originalType)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <Table
              size="small"
              onRowClick={(e, row) => {
                API.get(`/mailer/${row.ip}`).then(({ data }) => {
                  const { ip, logs, status } = data;
                  setLogMailer({ ip, logs: (logs || []).reverse(), status });
                });
              }}
              data={
                mailers
                  // .filter((a) => (user.username === 'admin' ? a.status : true))
                  // .sort((b, a) => (a.type !== 'Pending' ? -a.createdAt + b.createdAt : a.originalType > b.originalType ? 1 : a.originalType < b.originalType ? -1 : 0))
                  .sort((a, b) => (a.status?.createdAt && b.status?.createdAt ? b.status.successSent - a.status.successSent : a.status?.createdAt && !b.status?.createdAt ? -1 : 1))
                  .filter((a) => (a.type === 'Pending' && filterOriginalType ? a.originalType === filterOriginalType : true))
                  .map((a, index) => ({ ...a, index: offset * 50 + index + 1, enabled: !!a.status?.createdAt })) || []
              }
              columns={[
                {
                  title: 'No',
                  attribute: 'index',
                },
                {
                  title: 'Created',
                  Cell: ({ status, enabled, createdAt }) => (
                    <div>
                      <div style={{ color: enabled ? 'inherit' : 'gray' }}>{new Date(status?.createdAt || createdAt).toLocaleTimeString()}</div>
                      {createdAt && <div style={{ color: 'gray' }}>{toHHMMSS(Math.floor((new Date().getTime() - createdAt) / 1000).toString())}</div>}
                    </div>
                  ),
                },
                {
                  title: 'IP',
                  attribute: 'ip',
                  Cell: ({ ip, enabled, subIPs, status, senderType }) => (
                    <>
                      <Copy value={ip}>
                        {colorIP(enabled, { ...status, goodIPs: senderType === 'DDOS' ? [ip] : status.goodIPs }, ip)}
                      </Copy>
                      <div style={{ color: enabled ? '#aba' : 'gray', display: 'flex', flexWrap: 'wrap', maxWidth: 300 }}>
                        {subIPs?.filter(Boolean).map((subIp, index) => (
                          <span key={index} style={{ padding: 3, borderRadius: 3, margin: 2 }}>
                            {colorIP(enabled, status, subIp)}
                          </span>
                        ))}
                      </div>
                    </>
                  ),
                },
                
                {
                  title: 'Password / Key',
                  attribute: 'password',
                  Cell: ({ user, password, privateKey, enabled }) => (
                    <div style={{ color: enabled ? 'inherit' : 'gray' }}>
                      {user !== 'root' ? user : ''}
                      {password && <Copy value={password}>{password}</Copy>}
                      {privateKey && (
                        <Copy value={privateKey}>
                          {privateKey?.slice(0, 40)}
                          {privateKey.length > 40 ? '...' : ''}
                        </Copy>
                      )}
                    </div>
                  ),
                },
                {
                  title: 'Type',
                  attribute: 'type',
                  Cell: ({ type, originalType, status, enabled, senderType }) => (
                    <>
                      <div style={{ color: enabled ? '#ccc' : 'gray', marginBottom: 1 }}>
                        {type} {type === 'Pending' ? `(${originalType})` : ' '}
                      </div>
                      <span style={{ color: status?.senderType === 'HOTMAIL' ? 'lightgreen' : status?.senderType === 'OTHERMAIL' ? '#AAF' : status?.senderType === 'BOTH' ? '#fc3' : '#888' }}>
                        {status?.senderType || senderType || ''}
                      </span>
                    </>
                  ),
                },
                {
                  title: 'Speed (Hot/other)',
                  Cell: ({ speed, hotmailSpeed, othermailSpeed, enabled, subIPs, status, senderType }) =>
                    senderType === 'DDOS' ? (
                      ''
                    ) : status.goodIPs ? (
                      <span style={{ color: enabled ? 'inherit' : 'gray' }}>
                        {senderType !== 'OTHERMAIL' ? `${status.goodIPs?.length || 0} X ${hotmailSpeed || speed}` : ''} <br />
                        {senderType !== 'HOTMAIL' ? `${status.goodIPs?.length || 0} X ${othermailSpeed || speed}` : ''}
                      </span>
                    ) : subIPs && subIPs.length ? (
                      <span style={{ color: enabled ? 'inherit' : 'gray' }}>
                        <span style={{ color: enabled ? 'inherit' : 'gray' }}>
                          {senderType !== 'OTHERMAIL' ? `${subIPs.length} X ${hotmailSpeed || speed}` : ''} <br />
                          {senderType !== 'HOTMAIL' ? `${subIPs.length} X ${othermailSpeed || speed}` : ''}
                        </span>
                      </span>
                    ) : (
                      <span style={{ color: enabled ? 'inherit' : 'gray' }}>
                        <span style={{ color: enabled ? 'inherit' : 'gray' }}>
                          {senderType !== 'OTHERMAIL' ? hotmailSpeed || speed : ''} <br />
                          {senderType !== 'HOTMAIL' ? `${othermailSpeed || speed}` : ''}
                        </span>
                      </span>
                    ),
                },
                {
                  title: 'Domain / PTR',
                  attribute: 'domain',
                  Cell: ({ domain, ptr, status, enabled, senderType }) =>
                    senderType === 'DDOS' ? (
                      ''
                    ) : (
                      <div style={{ color: enabled ? 'inherit' : 'gray' }}>
                        <Copy value={status.domain}>{status.domain}</Copy>
                        {/* {status?.domain && status.domain !== domain && <span style={{ color: '#f83' }}>Pls reinstall ({status.domain})</span>} */}
                        <span style={{ color: enabled ? '#8f36' : 'gray' }}>{ptr || '- - -'}</span>
                      </div>
                    ),
                },

                {
                  title: 'Version',
                  Cell: ({ status, enabled }) => <div style={{ color: enabled ? 'inherit' : 'gray' }}>{status?.version || '-'}</div>,
                },
                {
                  title: 'Sent',
                  Cell: ({ status, enabled, senderType }) =>
                    senderType === 'DDOS' ? (
                      ''
                    ) : (
                      <div style={{ color: enabled ? 'inherit' : 'gray' }}>
                        <span style={{ color: 'green' }}>{status?.successSent}</span> / <span style={{ color: '#ccc' }}>{status?.sent}</span>
                      </div>
                    ),
                },
                {
                  title: 'Detail',
                  Cell: ({ status, enabled }) => <pre>{status?.status || 'installing...'}</pre>,
                },
                {
                  title: 'Action',
                  Cell: (row) => (
                    <>
                      <ButtonGroup size="small">
                        {/* <Button title="Update" onClick={() => setCurrentMailer(row)}>
                        <Icons.Update fontSize="small" />
                      </Button> */}
                        {row.senderType !== 'DDOS' && (
                          <Button title="Test Mailer" onClick={() => setSendTestMailer(row.ip)}>
                            <Icons.Send fontSize="small" />
                          </Button>
                        )}

                        <Button title="Install Mailer" onClick={() => API.post(`/mailer/${row.ip}/install`)}>
                          <Icons.CloudDownload fontSize="small" />
                        </Button>
                        {row.senderType !== 'DDOS' && (
                          <Button
                            title="Update Mailer"
                            onClick={() => {
                              if (row.type === 'mailgun') setCurrentMailGun(row);
                              else if (row.type === 'sendinblue') setCurrentSendInBlue(row);
                              else if (row.type === 'localweb') setCurrentLocalWeb(row);
                              else if (row.type === 'sendgrid') setCurrentSendGrid(row);
                              else setCurrentMailer(row);
                            }}
                            disabled={row.senderType === 'DDOS'}
                          >
                            <Icons.Edit fontSize="small" />
                          </Button>
                        )}

                        <Button
                          title="Remover"
                          onClick={() => {
                            // if (window.confirm(`Are you sure to delete Mailer`)) {
                            API.delete(`/mailer/${row.ip}`).then(() => refresh());
                            // }
                          }}
                          // disabled={user.username === 'admin'}
                        >
                          <Icons.Delete fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </>
                  ),
                },
              ]}
              pagination={true}
              paginationTop={true}
              rowsPerPage={10}
            />
          </>
        )}
      </Box>
      {logMailer?.ip && (
        <Box component={Paper} mb={2} padding={1}>
          <div style={{ backgroundColor: '#0006', padding: 8, display: 'flex', justifyContent: 'space-between' }}>
            <span>
              <FormControlLabel
                control={
                  <Switch
                    checked={filterSuccessLog}
                    onChange={(e, value) => {
                      setFilterSuccessLog(value);
                    }}
                  />
                }
                label={filterSuccessLog ? `Logs of Success from ${logMailer?.ip}` : `Logs of Failed from ${logMailer?.ip}`}
              />
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Sent: {logMailer?.status?.sent} = <span style={{ color: 'lightgreen' }}>{logMailer?.status?.successSent}</span> +{' '}
              <span style={{ color: '#f888' }}>{logMailer?.status?.failSent}</span>
            </span>
            <Button onClick={() => setShowLog((current) => !current)}>{!showLog ? <Icons.ArrowDropDown /> : <Icons.ArrowDropUp />}</Button>
          </div>
          {showLog && (
            <div style={{ backgroundColor: '#111', padding: 5 }}>
              {(logMailer?.logs || [])
                .filter((l) => !!l.success === filterSuccessLog)
                .map((log, index) => (
                  <div key={index} style={{ color: log?.success ? 'green' : '#F888', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    <span style={{ color: '#eed8' }}>[{new Date(log?.sentAt).toLocaleString()}]</span> {log?.email}: {log?.message || 'no message'}
                  </div>
                ))}
            </div>
          )}
        </Box>
      )}

      <SendMail
        onClose={() => {
          setUpsertTask(null);
          refresh();
        }}
        task={upsertTask}
      />

      <Dialog open={!!sendTestMailer} onClose={() => setSendTestMailer(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            localStorage.setItem('test-mailtemplate', param.templateId);
            localStorage.setItem('test-email', param.email);
            localStorage.setItem('test-track-id', param.trackId);
            localStorage.setItem('test-first_name', param.first_name);
            localStorage.setItem('test-last_name', param.last_name);
            localStorage.setItem('test-cpf', param.cpf);
            API.post(`/mailer/test/${sendTestMailer}`, {
              templateId: param.templateId,
              client: {
                emails: param.email,
                trackId: param.trackId,
                name: param.first_name + ' ' + param.last_name,
                cpf: param.cpf || '00000000000',
              },
            }).then((res) => setSendTestMailer(null));
          }}
        >
          <DialogTitle>Send test mail</DialogTitle>
          <DialogContent>
            <Input name="templateId" select label="Mail Template" defaultValue={localStorage.getItem('test-mailtemplate')} required>
              {(templates || []).map((template, index) => (
                <MenuItem key={template._id} value={template._id} selected={template._id === localStorage.getItem('test-mailtemplate')}>
                  <span style={{ color: 'lightgreen', minWidth: 100 }}>{template.senderName}</span>&nbsp;&nbsp; {template.subject}
                </MenuItem>
              ))}
            </Input>
            <p />
            <Input name="email" type="text" label="Email" defaultValue={localStorage.getItem('test-email')} />
            <p />
            {/* <Input name="trackId" type="text" label="Tracking Id" defaultValue={localStorage.getItem('test-track-id')} />
            <p /> */}
            <Input name="first_name" type="text" label="First Name" defaultValue={localStorage.getItem('test-first_name')} />
            <p />
            <Input name="last_name" type="text" label="Last Name" defaultValue={localStorage.getItem('test-last_name')} />
            <p />
            <Input name="cpf" type="text" label="CPF" defaultValue={localStorage.getItem('test-cpf')} />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSendTestMailer(null)} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary">
              Test Send
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={Boolean(blockedEmailHashes)} onClose={() => setBlockedEmailHashes(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            if (param.emailhashes)
              API.post(`/settings/blocked`, {
                emailhashes: param.emailhashes
                  .split(',')
                  .map((hash) => hash.trim())
                  .filter(Boolean),
              });
            setBlockedEmailHashes(null);
          }}
        >
          <DialogTitle>Blocked Email Hashes</DialogTitle>
          {/* <DialogContent>
            <Input autoFocus label="Email Hashes" name="emailhashes" rows="3" defaultValue={blockedEmailHashes} multiline />
          </DialogContent> */}
          <DialogContent>
            <Table
              data={(blockedEmailHashes || '')
                .split(',')
                .map((h) => h.trim())
                .filter(Boolean)
                .map((hash, index) => ({ hash, index: index + 1, email: atob(Buffer.from(hash, 'hex').toString()).toLowerCase() }))}
              columns={[
                {
                  title: 'Index',
                  attribute: 'index',
                },
                {
                  title: 'Hash',
                  attribute: 'hash',
                  Cell: ({ hash }) => <Copy value={hash}>{hash.length > 20 ? `${hash.slice(0, 20)}...` : hash}</Copy>,
                },
                {
                  title: 'Email',
                  attribute: 'email',
                },
                {
                  title: '',
                  Cell: ({ hash }) => (
                    <Icons.Delete
                      fontSize="small"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const emailhashes = blockedEmailHashes
                          .split(',')
                          .map((hash) => hash.trim())
                          .filter(Boolean)
                          .filter((h) => h !== hash);
                        API.post(`/settings/blocked`, {
                          emailhashes,
                        });
                        setBlockedEmailHashes(emailhashes.join(','));
                      }}
                    />
                  ),
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                let hash = window.prompt('Input hash');
                if (hash) {
                  try {
                    hash = hash.trim();
                    const email = atob(Buffer.from(hash, 'hex').toString()).toLowerCase();
                    const emailhashes = blockedEmailHashes
                      .split(',')
                      .map((hash) => hash.trim())
                      .filter(Boolean)
                      .concat([hash]);
                    API.post(`/settings/blocked`, {
                      emailhashes,
                    });
                    setBlockedEmailHashes(emailhashes.join(','));
                  } catch (e) {
                    window.alert(`invalid hash`);
                  }
                }
              }}
            >
              Add One
            </Button>
            {/* <Button type="submit" color="primary">
              Save
            </Button> */}
            <Button onClick={() => setBlockedEmailHashes(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!currentMailer} onClose={() => setCurrentMailer(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            API.post(`/public/mailer/deploy`, {
              ...param,
              owner: user.username,
              subIPs: (param.subIPs || '')
                .split(',')
                .map((ip) => ip.trim())
                .filter(Boolean),
            })
              .then(() => {
                if (currentMailer?._id) setCurrentMailer(null);
                else alert('success to deploy!');
              })
              .catch(console.error);
          }}
        >
          <DialogTitle>Deploy Postfix</DialogTitle>
          <DialogContent>
            <Input type="text" name="_id" defaultValue={currentMailer?._id} style={{ display: 'none' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="IP" type="text" name="ip" autoFocus defaultValue={currentMailer?.ip} disabled={currentMailer?._id} required />
              &nbsp;&nbsp;
              <Input label="User" type="text" name="user" autoFocus defaultValue={currentMailer?.user || 'root'} disabled={currentMailer?._id} />
              &nbsp;&nbsp;
              <Input label="Password" type="text" name="password" defaultValue={currentMailer?.password} disabled={currentMailer?._id} />
              {/* &nbsp;&nbsp;
                <Input label="Domain" type="text" name="domain" defaultValue={currentMailer?.domain} required /> */}
            </div>
            <p />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Group Name" type="text" name="type" defaultValue={currentMailer?.type || 'MailerGroup001'} required />
              &nbsp;&nbsp;
              <Input label="senderType" select name="senderType" defaultValue={currentMailer?.senderType || 'HOTMAIL'} required>
                <MenuItem key="HOTMAIL" value="HOTMAIL">
                  Hotmail
                </MenuItem>
                <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                  OtherMail
                </MenuItem>
                <MenuItem key="BOTH" value="BOTH">
                  Both
                </MenuItem>
                <MenuItem key="DDOS" value="DDOS">
                  DDOS
                </MenuItem>
              </Input>
            </div>
            <p />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentMailer?.hotmailSpeed || currentMailer?.speed || 150} required />
              &nbsp;&nbsp;
              <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentMailer?.othermailSpeed || currentMailer?.speed || 4} required />
            </div>
            <p />
            <Input label="SSH Private Key" type="text" name="privateKey" multiline rows={3} defaultValue={currentMailer?.privateKey} disabled={currentMailer?._id} />
            <p />
            <Input label="Sub IPs" type="text" name="subIPs" defaultValue={(currentMailer?.subIPs || []).join(', ')} multiline rows={3} />
            <p />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentMailer(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {currentMailer?._id ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!currentOutlook} onClose={() => setCurrentOutlook(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            API.post(`/public/mailer/deploy`, {
              ...param,
              owner: user.username,
            })
              .then(() => {
                if (currentOutlook?.ip) setCurrentOutlook(null);
                else alert('success to deploy!');
              })
              .catch(console.error);
          }}
        >
          <DialogTitle>Deploy Outlook</DialogTitle>
          <DialogContent>
            <Input type="text" name="_id" defaultValue={currentOutlook?._id} style={{ display: 'none' }} />
            <Input type="text" name="type" value="outlook" style={{ display: 'none' }} />
            <Input label="Email" type="text" name="ip" defaultValue={currentOutlook?.ip} disabled={currentOutlook?.ip} required />
            <p />
            <Input label="Password" type="text" name="password" defaultValue={currentOutlook?.password} required />
            <p />
            <Input label="senderType" select name="senderType" defaultValue={currentOutlook?.senderType} required>
              <MenuItem key="HOTMAIL" value="HOTMAIL">
                Hotmail
              </MenuItem>
              <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                OtherMail
              </MenuItem>
              <MenuItem key="BOTH" value="BOTH">
                Both
              </MenuItem>
            </Input>
            <p />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentOutlook?.hotmailSpeed || currentOutlook?.speed || 1} required />
              &nbsp;&nbsp;
              <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentOutlook?.othermailSpeed || currentOutlook?.speed || 1} required />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentOutlook(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {currentOutlook?.ip ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!currentMailGun} onClose={() => setCurrentMailGun(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            API.post(`/public/mailer/deploy`, {
              ...param,
              owner: user.username,
              privateKey: `${param.dkimSelector.trim()}|${param.dkimKey}`,
              ip: `mailgun-${param.domain}`,
            })
              .then(() => {
                if (currentMailGun?.ip) setCurrentMailGun(null);
                else alert('success to deploy!');
              })
              .catch(console.error);
          }}
        >
          <DialogTitle>Deploy MailGun</DialogTitle>
          <DialogContent>
            <Input type="text" name="_id" defaultValue={currentMailGun?._id} style={{ display: 'none' }} />
            <Input type="text" name="type" value="mailgun" style={{ display: 'none' }} />
            <Input label="Domain" type="text" name="domain" defaultValue={currentMailGun?.domain} disabled={currentMailGun?.domain} required />
            <p />
            <Input label="Private API" type="text" name="password" defaultValue={currentMailGun?.password} required />
            <p />
            <Input label="DKIM selector" type="text" name="dkimSelector" defaultValue={currentMailGun?.privateKey?.split('|')[0]} />
            <p />
            <Input label="DKIM public key" type="text" name="dkimKey" multiline rows={3} defaultValue={currentMailGun?.privateKey?.split('|')[1]} />
            <p />
            <Input label="senderType" select name="senderType" defaultValue={currentMailGun?.senderType} required>
              <MenuItem key="HOTMAIL" value="HOTMAIL">
                Hotmail
              </MenuItem>
              <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                OtherMail
              </MenuItem>
              <MenuItem key="BOTH" value="BOTH">
                Both
              </MenuItem>
            </Input>
            <p />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentMailGun?.hotmailSpeed || currentMailGun?.speed || 150} required />
              &nbsp;&nbsp;
              <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentMailGun?.othermailSpeed || currentMailGun?.speed || 4} required />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentMailGun(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {currentMailGun?.ip ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!currentSendInBlue} onClose={() => setCurrentSendInBlue(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            API.post(`/public/mailer/deploy`, {
              ...param,
              owner: user.username,
              ip: currentSendInBlue?.ip || `sendinblue-${new Date().getTime()}`,
            })
              .then(() => {
                if (currentSendInBlue?.ip) setCurrentSendInBlue(null);
                else alert('success to deploy!');
              })
              .catch(console.error);
          }}
        >
          <DialogTitle>Deploy SendInBlue</DialogTitle>
          <DialogContent>
            <Input type="text" name="_id" defaultValue={currentSendInBlue?.ip} style={{ display: 'none' }} />
            <Input type="text" name="type" value="sendinblue" style={{ display: 'none' }} />
            <Input label="Domain" type="text" name="domain" value="-" style={{ display: 'none' }} />
            <p />
            <Input label="API" type="text" name="password" defaultValue={currentSendInBlue?.password} required />
            <p />
            <p />
            <Input label="senderType" select name="senderType" defaultValue={currentSendInBlue?.senderType} required>
              <MenuItem key="HOTMAIL" value="HOTMAIL">
                Hotmail
              </MenuItem>
              <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                OtherMail
              </MenuItem>
              <MenuItem key="BOTH" value="BOTH">
                Both
              </MenuItem>
            </Input>
            <p />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentSendInBlue?.hotmailSpeed || currentSendInBlue?.speed || 150} required />
              &nbsp;&nbsp;
              <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentSendInBlue?.othermailSpeed || currentSendInBlue?.speed || 4} required />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentSendInBlue(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {currentSendInBlue?.ip ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!currentLocalWeb} onClose={() => setCurrentLocalWeb(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            API.post(`/public/mailer/deploy`, {
              ...param,
              owner: user.username,
              ip: currentLocalWeb?.ip || `localweb-${new Date().getTime()}`,
            })
              .then(() => {
                if (currentLocalWeb?.ip) setCurrentLocalWeb(null);
                else alert('success to deploy!');
              })
              .catch(console.error);
          }}
        >
          <DialogTitle>Deploy LocalWeb</DialogTitle>
          <DialogContent>
            <Input type="text" name="_id" defaultValue={currentLocalWeb?.ip} style={{ display: 'none' }} />
            <Input type="text" name="type" value="localweb" style={{ display: 'none' }} />
            <Input label="Domain" type="text" name="domain" value="-" style={{ display: 'none' }} />
            <p />
            <Input label="SMTP User " type="text" name="password" defaultValue={currentLocalWeb?.password} required />
            <p />
            <Input label="SMTP Pass" type="text" name="privateKey" defaultValue={currentLocalWeb?.privateKey} required />
            <p />
            <Input label="senderType" select name="senderType" defaultValue={currentLocalWeb?.senderType} required>
              <MenuItem key="HOTMAIL" value="HOTMAIL">
                Hotmail
              </MenuItem>
              <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                OtherMail
              </MenuItem>
              <MenuItem key="BOTH" value="BOTH">
                Both
              </MenuItem>
            </Input>
            <p />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentLocalWeb?.hotmailSpeed || currentLocalWeb?.speed || 150} required />
              &nbsp;&nbsp;
              <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentLocalWeb?.othermailSpeed || currentLocalWeb?.speed || 4} required />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentLocalWeb(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {currentLocalWeb?.ip ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Dialog open={!!currentSendGrid} onClose={() => setCurrentSendGrid(null)} fullWidth>
        <Form
          onSubmit={(param) => {
            API.post(`/public/mailer/deploy`, {
              ...param,
              owner: user.username,
              ip: currentSendGrid?.ip || `sendgrid-${new Date().getTime()}`,
            })
              .then(() => {
                if (currentSendGrid?.ip) setCurrentSendGrid(null);
                else alert('success to deploy!');
              })
              .catch(console.error);
          }}
        >
          <DialogTitle>Deploy LocalWeb</DialogTitle>
          <DialogContent>
            <Input type="text" name="_id" defaultValue={currentSendGrid?.ip} style={{ display: 'none' }} />
            <Input type="text" name="type" value="sendgrid" style={{ display: 'none' }} />
            <Input label="Domain" type="text" name="domain" value="-" style={{ display: 'none' }} />
            <Input label="SMTP User " type="text" name="password" defaultValue={currentSendGrid?.password} required />
            <p />
            <Input label="SMTP Pass" type="text" name="privateKey" defaultValue={currentSendGrid?.privateKey} required />
            <p />
            <Input label="senderType" select name="senderType" defaultValue={currentSendGrid?.senderType} required>
              <MenuItem key="HOTMAIL" value="HOTMAIL">
                Hotmail
              </MenuItem>
              <MenuItem key="OTHERMAIL" value="OTHERMAIL">
                OtherMail
              </MenuItem>
              <MenuItem key="BOTH" value="BOTH">
                Both
              </MenuItem>
            </Input>
            <p />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input label="Hotmail Speed" type="number" name="hotmailSpeed" defaultValue={currentSendGrid?.hotmailSpeed || currentSendGrid?.speed || 150} required />
              &nbsp;&nbsp;
              <Input label="Othermail Speed" type="number" name="othermailSpeed" defaultValue={currentSendGrid?.othermailSpeed || currentSendGrid?.speed || 4} required />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentSendGrid(null)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {currentSendGrid?.ip ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

function toHHMMSS(str) {
  var sec_num = parseInt(str, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}