import axios from 'axios';
import StorageHelper from '../helpers/StorageHelper';
import config from '../config'; // Importação correta


const createAPI = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config) => {
    const token = StorageHelper.getToken();

    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
  });

  return instance;
};

export const API = createAPI(config.serverSv); 


export function parseParams(data = {}) {
  for (const attr in data) {
    let value = data[attr];

    if (typeof value === 'undefined') {
      continue;
    }

    if (typeof value === 'boolean') {
      value = Number(value);
    }

    if (value === null) {
      value = '';
    }

    data[attr] = value;
  }

  return data;
}

export function formData(data = {}) {
  data = parseParams(data);

  const form = new FormData();

  for (const attr in data) {
    form.append(attr, data[attr]);
  }

  return form;
}

export class Service {
  static service = '';
  static idAttribute = '_id';

  static list(params = {}) {
    return API.get(`${this.service}`, { params });
  }

  static get(id) {
    return API.get(`${this.service}/${id}`);
  }

  static create(data) {
    return API.post(`${this.service}`, data);
  }

  static update(data) {
    return API.put(`${this.service}/${data[this.idAttribute]}`, data);
  }

  static save(data) {
    return data[this.idAttribute] ? this.update(data) : this.create(data);
  }

  static delete(id) {
    return API.delete(`${this.service}/${id}`);
  }

  static image(id, file = null) {
    const data = formData({ imagem: file });
    return API.post(`${this.service}/${id}/image`, data);
  }
}

export default API;
